import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { WebapiService } from 'src/app/ClassFile/webapi.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnDestroy, OnInit {

  getCustomerListArray : any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {


  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    //this.dtTrigger.next();
  }
 

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
      deferRender: true,
      destroy:true,
      ordering: true,
    };
   // this.ViewList();
  }

  reloadPage(){
    window.location.reload();
  }

  

  ViewList(){

    this.getCustomerListArray = [];



    this.webApiService.activeCustomerList().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getCustomerListArray = res;

      
          this.dtTrigger.next();

        //  console.log(this.getCustomerListArray[0]);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

  }

  ViewListTodayList(){

    this.getCustomerListArray = [];
   

    this.webApiService.getTodayCustomerList().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getCustomerListArray = res;

         this.dtTrigger.next();
         //console.log(this.getCustomerListArray);

      
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

  }

  updateMemberID(id){
    console.log(id);
 
 
    Swal.fire({
      title: 'Enter MemberShip Card Number',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      inputPlaceholder: "SH-XXXXX",
      preConfirm: (login) => {

        this.update(id, login);
        // return fetch(`//api.github.com/users/${login}`)
        //   .then(response => { 
        //     if (!response.ok) {
        //       throw new Error(response.statusText)
        //     }
        //     return response.json()
        //   })
        //   .catch(error => {
        //     Swal.showValidationMessage(
        //       `Request failed: ${error}`
        //     )
        //   }) 
          
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire({
        //   title: `${result.value.login}'s avatar`,
        //   imageUrl: result.value.avatar_url
        // })
      }
    })





    
  }

  update(id, no){
 

    let data = {
      "id" : id,
      "no" : "SH-"+no
    }

    this.webApiService.updateMemberID(data).subscribe((res => {

      console.log(res);
      this.toastr.success("Successfuly Updated!", 'Success!');
    
      // let response = JSON.stringify(res);
      // let e_data = JSON.parse(response).Data;
      // let e_code = JSON.parse(response).Code;
      //this.ViewList();
      // if(e_code == "S100"){
      //   this.toastr.success(e_data, 'Info!');
      //   this.router.navigate(['/Login']);
      // }else if(e_code == "E100"){
      //   this.toastr.error(e_data, "Error!");
      // }else{
      //   this.toastr.error('Something went wrong Please try Again', 'Error!');
      // }

  }), (err) => {
      console.log(err);

      this.toastr.error('Something went wrong Please try Again', 'Error!');
  })












  }


}
