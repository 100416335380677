import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChannelingAPIService } from 'src/app/ClassFile/channel/channeling-api.service';

import Swal from 'sweetalert2';

declare var $: any;
import { Subject } from 'rxjs';

@Component({
  selector: 'app-doctor-by-channel-id',
  templateUrl: './doctor-by-channel-id.component.html',
  styleUrls: ['./doctor-by-channel-id.component.scss']
})
export class DoctorByChannelIDComponent implements OnInit {

  getID = "";
  getDoctorListArray ;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private toastr: ToastrService,private webApiService: ChannelingAPIService,private route: ActivatedRoute) {
    this.getID = this.route.snapshot.params.id;

  }

  ngOnInit(): void {

    this.dtOptions = {
      processing: true,
      deferRender: true,
      destroy:true
    };

    this.getChannel();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  clearTimeconfirm() {

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Clear All Times?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Clear!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.clearTime();
      }
    })




  }

  clearTime() { 

    
    const labData = {
      channelingID: this.getID,
    };

    this.webApiService.updateChannelTimeStatus(labData).subscribe((res => {

        this.toastr.success(res + "", 'Updated!');
        this.getChannel();

    }), (err) => {
      console.log(err);

        this.toastr.error('Something went wrong Please try Again', 'Error!');
    })

  }

  clearAvailibilityconfirm() {

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Clear All Availability Status?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Clear!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.clearAvailability();
      }
    })




  }

  clearAvailability() {
      //updateChannelingCenterAvailibilityStatus

      const labData = {
        channelingID: this.getID,
      };

      this.webApiService.updateChannelingCenterAvailibilityStatus(labData).subscribe((res => {
  
          this.toastr.success(res + "", 'Updated!');
          this.getChannel();
  
      }), (err) => {
        console.log(err);
  
          this.toastr.error('Something went wrong Please try Again', 'Error!');
      })



  }


  getChannel(){


    let userData = {
      id: this.getID,
    }

    this.getDoctorListArray;

    this.webApiService.doctorByChannelID(userData).subscribe((res => {
      //console.log(res);

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.getDoctorListArray = res;
        //this.dtTrigger.next();

      //  console.log(body);


      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })




  }

  updateAvailability(id, availability){

    let x = "";

    if(availability == "true"){
      x = "false"
    }else{
      x = "true"
    }

    const labData = {
      id: id,
      availability: x,
  };

    this.webApiService.changeDoctorAvailability(labData).subscribe((res => {

        this.toastr.success(res + "", 'Updated!');
        this.getChannel();

    }), (err) => {
      console.log(err);

        this.toastr.error('Something went wrong Please try Again', 'Error!');
    })


  }


}
