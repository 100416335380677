import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Home',
        type: 'item',
        url: '/Home',
        icon: 'feather icon-home',
        breadcrumbs: false
      },
    ]
  },
  {
    id: 'item',
    title: 'Item',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Job',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Scan Job',
            type: 'item',
            url: '/ScanJobs'
          },
          {
            id: 'vertical',
            title: 'On Going Job',
            type: 'item',
            url: '/OnGoingJob'
          },
          {
            id: 'horizontal',
            title: 'Completed Job',
            type: 'item',
            url: '/CompletedJob'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'Customer',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Customer Ragistration',
            type: 'item',
            url: '/add-customer' 
          },
          {
            id: 'vertical',
            title: 'Pending Request',
            type: 'item',
            url: '/PendingRequest'
          },
          {
            id: 'horizontal',
            title: 'All Customers',
            type: 'item',
            url: '/CustomerList'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'Lab',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Register Lab',
            type: 'item',
            url: '/LabRegister'
          },
          {
            id: 'vertical',
            title: 'Add Employee',
            type: 'item',
            url: '/AddEmployee'
          },
          {
            id: 'horizontal',
            title: 'Edit Labs',
            type: 'item',
            url: '/LabList'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'Lab Testing',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Testing',
            type: 'item',
            url: '/LabTesting'
          },
          {
            id: 'vertical',
            title: 'View',
            type: 'item',
            url: '/TestingList'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'Packages',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Create Package',
            type: 'item',
            url: '/AddPackages'
          },
          {
            id: 'vertical',
            title: 'Mange Package',
            type: 'item',
            url: '/PackageList'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'Drivers',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Add Drivers',
            type: 'item',
            url: '/AddDrivers'
          },
          {
            id: 'vertical',
            title: 'Mange Drivers',
            type: 'item',
            url: '/AddQuestion'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'User Account',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Create Account',
            type: 'item',
            url: '/CreateAccount'
          },
        ]
      },
      // {
      //   id: 'dashboard',
      //   title: 'User List',
      //   type: 'item',
      //   url: '/User',
      //   icon: 'feather icon-user'
      // },
      // {
      //   id: 'dashboard',
      //   title: 'Modules',
      //   type: 'item',
      //   url: '/Modules',
      //   icon: 'feather icon-book'
      // },
      // {
      //   id: 'dashboard',
      //   title: 'Question',
      //   type: 'collapse',
      //   url: '/Question',
      //   icon: 'feather icon-upload',
      //   children: [
      //     {
      //       id: 'vertical',
      //       title: 'Add Question',
      //       type: 'item',
      //       url: '/AddQuestion'
      //     },
      //     {
      //       id: 'horizontal',
      //       title: 'Edit Question',
      //       type: 'item',
      //       url: '/EditQuestion'
      //     }
      //   ]
      // },
      // {
      //   id: 'dashboard',
      //   title: 'Report',
      //   type: 'item',
      //   url: '/Report',
      //   icon: 'feather icon-bar-chart'
      // },
      // {
      //   id: 'page-layouts',
      //   title: 'Setting',
      //   type: 'collapse',
      //   icon: 'feather icon-menu',
      //   children: [
      //     {
      //       id: 'vertical',
      //       title: 'Bank Details',
      //       type: 'item',
      //       url: '/Bank',
      //     },
      //   ]
      // }
    ]
  },
  {
    id: 'channeling',
    title: 'Channeling',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Channeling Center',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Request Channeling',
            type: 'item',
            url: '/Request-Channeling'
          },
          {
            id: 'vertical',
            title: 'Create Center',
            type: 'item',
            url: '/register-center'
          },
          {
            id: 'horizontal',
            title: 'All Centers',
            type: 'item',
            url: '/channeling-list'
          },
          {
            id: 'vertical',
            title: 'Add Specialization',
            type: 'item',
            url: '/add-specialization'
          }
        ]
      },
      {
        id: 'dashboard',
        title: 'Doctor',
        type: 'collapse',
        icon: 'feather icon-upload',
        children: [
          {
            id: 'vertical',
            title: 'Add Doctor',
            type: 'item',
            url: '/add-doctor'
          },
          {
            id: 'vertical',
            title: 'Mange Doctor',
            type: 'item',
            url: '/AddQuestion'
          }
        ]
      },
    ]
  },
  // {
  //   id: 'ui-element',
  //   title: 'UI ELEMENT',
  //   type: 'group',
  //   icon: 'feather icon-layers',
  //   children: [
  //     {
  //       id: 'basic',
  //       title: 'Basic',
  //       type: 'collapse',
  //       icon: 'feather icon-box',
  //       children: [
  //         {
  //           id: 'alert',
  //           title: 'Alert',
  //           type: 'item',
  //           url: '/basic/alert'
  //         },
  //         {
  //           id: 'button',
  //           title: 'Button',
  //           type: 'item',
  //           url: '/basic/button'
  //         },
  //         {
  //           id: 'badges',
  //           title: 'Badges',
  //           type: 'item',
  //           url: '/basic/badges'
  //         },
  //         {
  //           id: 'breadcrumb-pagination',
  //           title: 'Breadcrumbs & Pagination',
  //           type: 'item',
  //           url: '/basic/breadcrumb-paging'
  //         },
  //         {
  //           id: 'cards',
  //           title: 'Cards',
  //           type: 'item',
  //           url: '/basic/cards'
  //         },
  //         {
  //           id: 'collapse',
  //           title: 'Collapse',
  //           type: 'item',
  //           url: '/basic/collapse'
  //         },
  //         {
  //           id: 'carousel',
  //           title: 'Carousel',
  //           type: 'item',
  //           url: '/basic/carousel'
  //         },
  //         {
  //           id: 'grid-system',
  //           title: 'Grid System',
  //           type: 'item',
  //           url: '/basic/grid-system'
  //         },
  //         {
  //           id: 'progress',
  //           title: 'Progress',
  //           type: 'item',
  //           url: '/basic/progress'
  //         },
  //         {
  //           id: 'modal',
  //           title: 'Modal',
  //           type: 'item',
  //           url: '/basic/modal'
  //         },
  //         {
  //           id: 'spinner',
  //           title: 'Spinner',
  //           type: 'item',
  //           url: '/basic/spinner'
  //         },
  //         {
  //           id: 'tabs-pills',
  //           title: 'Tabs & Pills',
  //           type: 'item',
  //           url: '/basic/tabs-pills'
  //         },
  //         {
  //           id: 'typography',
  //           title: 'Typography',
  //           type: 'item',
  //           url: '/basic/typography'
  //         },
  //         {
  //           id: 'tooltip-popovers',
  //           title: 'Tooltip & Popovers',
  //           type: 'item',
  //           url: '/basic/tooltip-popovers'
  //         },
  //         {
  //           id: 'toasts',
  //           title: 'Toasts',
  //           type: 'item',
  //           url: '/basic/toasts'
  //         },
  //         {
  //           id: 'other',
  //           title: 'Other',
  //           type: 'item',
  //           url: '/basic/other'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 'forms-tables',
  //   title: 'Forms & Tables',
  //   type: 'group',
  //   icon: 'feather icon-layout',
  //   children: [
  //     {
  //       id: 'forms-element',
  //       title: 'Forms',
  //       type: 'item',
  //       url: '/forms/basic',
  //       icon: 'feather icon-file-text'
  //     },
  //     {
  //       id: 'bootstrap',
  //       title: 'Table',
  //       type: 'item',
  //       url: '/tbl-bootstrap/bt-basic',
  //       icon: 'feather icon-server'
  //     }
  //   ]
  // },
  // {
  //   id: 'chart-maps',
  //   title: 'Chart & Maps',
  //   type: 'group',
  //   icon: 'feather icon-pie-chart',
  //   children: [
  //     {
  //       id: 'charts',
  //       title: 'Charts',
  //       type: 'item',
  //       url: '/charts/apex',
  //       icon: 'feather icon-pie-chart'
  //     },
  //     {
  //       id: 'maps',
  //       title: 'Maps',
  //       type: 'item',
  //       url: '/maps/google',
  //       icon: 'feather icon-map'
  //     }
  //   ]
  // },
  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   type: 'group',
  //   icon: 'feather icon-file-text',
  //   children: [
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'signup',
  //           title: 'Sign up',
  //           type: 'item',
  //           url: '/auth/signup',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signin',
  //           title: 'Sign in',
  //           type: 'item',
  //           url: '/auth/signin',
  //           target: true,
  //           breadcrumbs: false
  //         }
  //       ]
  //     },
  //     {
  //       id: 'sample-page',
  //       title: 'Sample Page',
  //       type: 'item',
  //       url: '/sample-page',
  //       classes: 'nav-item',
  //       icon: 'feather icon-sidebar'
  //     }
  //   ]
  // }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
