<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
        <div class="table-responsive ng-tns-c94-73">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover" data-page-length='10'>
          <thead>
          <tr>
            <th><span>Lab Name</span></th>
            <th><span>Testing Name</span></th>
            <th><span>Description</span></th>
            <th><span>Price</span></th>
            <th><span>Availability</span></th>
            <th><span>Action</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getTestingListArray">
              <td>{{ val.lab[0] == null ? val.lab : val.lab[0].name }}</td>
              <td>{{ val.name }}</td>
              <td>{{ val.description }}</td>
              <td>
                <h5><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-secondary ng-tns-c85-32">{{  val.unitPrice  }}</span></h5>
              </td>
              <td>
                <h5 *ngIf="val.customAvailable == 'Yes' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.customAvailable }}</span></h5>
                <h5 *ngIf="val.customAvailable == 'No' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-warning ng-tns-c85-32">{{ val.customAvailable }}</span></h5>
              </td>
              <td>
                <button type="button" class="btn btn-icon btn-dark ng-tns-c85-29">
                  <i _ngcontent-khb-c116="" [routerLink]="['/EditBloodTesting/',val._id]" class="feather icon-edit"></i>
                </button>
                <button type="button" style="margin-left: 10px;" class="btn btn-icon btn-danger ng-tns-c85-29" (click)="delete(val._id)">
                  <i _ngcontent-khb-c116="" class="feather icon-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
    </app-card>
  </div>
</div>
